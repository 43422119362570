<template>
<b-card v-if="users" no-body class="card-company-table">
    <b-table :items="users" responsive :fields="fields" class="mb-0">
        <!-- Id -->
        <template #cell(id)="data">
            <span class="font-weight-bolder mb-12">#{{data.index+1 }}</span>
        </template>

        <!-- CreatedAt Time -->
        <template #cell(createdAt)="data">
            <span class="font-weight-bolder mb-12">{{
          data.item.createdAt | moment("DD.MM.YYYY hh:mm")
        }}</span>
        </template>

        <!-- Status -->
        <template #cell(isActive)="data">
            <b-badge pill :variant="data.item.isActive ? 'light-success' : 'light-danger'">
                {{ data.item.isActive ? "AKTİF" : "PASİF" }}
            </b-badge>
        </template>

        <!-- Campaign -->
        <template #cell(campaignEmail)="data">
            <b-badge pill :variant="data.item.campaignEmail ? 'light-success' : 'light-danger'">
                {{ data.item.campaignEmail ? "EVET" : "HAYIR" }}
            </b-badge>
        </template>
        <template #cell(campaignSms)="data">
            <b-badge pill :variant="data.item.campaignSms ? 'light-success' : 'light-danger'">
                {{ data.item.campaignSms ? "EVET" : "HAYIR" }}
            </b-badge>
        </template>
        <template #cell(campaignPhone)="data">
            <b-badge pill :variant="data.item.campaignPhone ? 'light-success' : 'light-danger'">
                {{ data.item.campaignPhone ? "EVET" : "HAYIR" }}
            </b-badge>
        </template>

        <template #cell(isGuidance)="data">
            <b-badge pill :variant="data.item.isGuidance ? 'light-success' : 'light-danger'">
                {{ data.item.isGuidance ? "EVET" : "HAYIR" }}
            </b-badge>
        </template>
        <template #cell(actions)="data">
            <b-button-group>
                <b-button variant="warning" @click="editTeacher(data.item)">
                    <feather-icon icon="EditIcon" />
                </b-button>
                <b-button variant="primary" @click="openLessonDetails(data.item)">
                    <feather-icon icon="MoreHorizontalIcon" />
                </b-button>
                <b-button variant="danger" @click="deleteTeacher(data.item)">
                    <feather-icon icon="DeleteIcon" />
                </b-button>
            </b-button-group>
        </template>

    </b-table>

    <!--Open Add User-->
    <b-sidebar sidebar-class="sidebar-lg" bg-variant="white" shadow backdrop no-header right v-model="addUserPanel">
        <template #default="{ hide }">
            <!-- Header -->
            <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
                <h5 class="mb-0">Yeni Kullanıcı Ekle</h5>

                <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
            </div>

            <!-- Body -->
            <b-form class="p-2" @submit.prevent>
                <b-form-group label="Kullanıcı Adı" label-for="name">
                    <b-form-input name="firstName" id="name" type="text" v-model="addUserRequest.firstName" />
                </b-form-group>

                <b-form-group label="Kullanıcı Soyadı" label-for="surname">
                    <b-form-input name="lastName" id="surname" type="text" v-model="addUserRequest.lastName" />
                </b-form-group>

                <b-form-group label="Kullanıcı Numarası" label-for="code">
                    <b-form-input id="code" type="text" v-model="addUserRequest.idNumber" />
                </b-form-group>

                <b-form-group label="E-Posta Adresi" label-for="email">
                    <b-form-input id="email" type="email" v-model="addUserRequest.email" />
                </b-form-group>

                <b-form-group label="Telefon Numarası" label-for="number">
                    <b-input-group>
                        <b-input-group-prepend is-text> (+90) </b-input-group-prepend>
                        <cleave id="phone" v-model="addUserRequest.gsmNumber" trim class="form-control" :raw="false" :options="{
                  phone: true,
                  phoneRegionCode: 'TR',
                }" placeholder="5455948640" />
                    </b-input-group>
                </b-form-group>

                <b-form-group label="Şifre" label-for="password">
                    <b-form-input id="password" type="password" v-model="addUserRequest.password" />
                </b-form-group>

                <b-form-group label="Cinsiyesi" label-for="from">
                    <v-select id="vue-select" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :reduce="(gender) => gender.name" label="name" :options="gender" v-model="addUserRequest.gender" />
                </b-form-group>
                <b-form-group label="Bilgilendirme" label-for="from">
                    <b-form-checkbox v-model="addUserRequest.campaignEmail">
                        E-Posta Bilgilendirme
                    </b-form-checkbox>
                    <b-form-checkbox v-model="addUserRequest.campaignSms">
                        Sms Bilgilendirme
                    </b-form-checkbox>
                    <b-form-checkbox v-model="addUserRequest.campaignPhone">
                        Aranarak Bilgilendirme
                    </b-form-checkbox>
                </b-form-group>
                <b-form-group label="Zoom Ayarları" label-for="from">
                    <b-form-checkbox v-model="addUserRequest.addZoom">
                        Kullanıcıyı Zoom'a da Ekle (Zoom Entegrayonunun girilmiş olması lazım)
                    </b-form-checkbox>
                </b-form-group>

                <b-form-group label="Rehberlik Ayarları" label-for="from">
                    <b-form-checkbox v-model="addUserRequest.isGuidance">
                        Kullanıcıyı Rehberlikci olarak ata
                    </b-form-checkbox>
                </b-form-group>
                <!-- Form Actions -->
                <div class="d-flex mt-2">
                    <b-button variant="primary" class="mr-2" type="submit" @click="addBranchUser">
                        Ekle
                    </b-button>
                    <b-button variant="outline-secondary" @click="hide">
                        Vazgeç
                    </b-button>
                </div>
            </b-form>
        </template>

    </b-sidebar>

    <!--Open Edit User-->
    <b-sidebar sidebar-class="sidebar-lg" bg-variant="white" shadow backdrop no-header right v-model="editUserPanel">
        <template #default="{ hide }">
            <!-- Header -->
            <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
                <h5 class="mb-0">Kullanıcı Düzenle</h5>

                <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
            </div>

            <!-- Body -->
            <b-form class="p-2" @submit.prevent>
                <b-form-group label="Kullanıcı Adı" label-for="name">
                    <b-form-input name="firstName" id="name" type="text" v-model="editTeacherRequest.firstName" />
                </b-form-group>

                <b-form-group label="Kullanıcı Soyadı" label-for="surname">
                    <b-form-input name="lastName" id="surname" type="text" v-model="editTeacherRequest.lastName" />
                </b-form-group>

                <b-form-group label="Kullanıcı Numarası" label-for="code">
                    <b-form-input id="code" type="text" v-model="editTeacherRequest.idNumber" />
                </b-form-group>

                <b-form-group label="E-Posta Adresi" label-for="email">
                    <b-form-input id="email" type="email" v-model="editTeacherRequest.email" />
                </b-form-group>

                <b-form-group label="Telefon Numarası" label-for="number">
                    <b-input-group>
                        <b-input-group-prepend is-text> (+90) </b-input-group-prepend>
                        <cleave id="phone" v-model="editTeacherRequest.gsmNumber" trim class="form-control" :raw="false" :options="{
                  phone: true,
                  phoneRegionCode: 'TR',
                }" placeholder="5455948640" />
                    </b-input-group>
                </b-form-group>

                <b-form-group label="Şifre" label-for="password">
                    <b-form-input id="password" type="password" v-model="editTeacherRequest.password" />
                </b-form-group>

                <b-form-group label="Cinsiyesi" label-for="from">
                    <v-select id="vue-select" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :reduce="(gender) => gender.name" label="name" :options="gender" v-model="editTeacherRequest.gender" />
                </b-form-group>
                <b-form-group label="Bilgilendirme" label-for="from">
                    <b-form-checkbox v-model="editTeacherRequest.campaignEmail">
                        E-Posta Bilgilendirme
                    </b-form-checkbox>
                    <b-form-checkbox v-model="editTeacherRequest.campaignSms">
                        Sms Bilgilendirme
                    </b-form-checkbox>
                    <b-form-checkbox v-model="editTeacherRequest.campaignPhone">
                        Aranarak Bilgilendirme
                    </b-form-checkbox>
                </b-form-group>
                <b-form-group label="Kullanıcı Durumu" label-for="from">
                    <b-form-checkbox v-model="editTeacherRequest.isActive">
                        Giriş Yapabilir
                    </b-form-checkbox>
                </b-form-group>
                <b-form-group label="Rehberlik Ayarları" label-for="from">
                    <b-form-checkbox v-model="editTeacherRequest.isGuidance">
                        Kullanıcıyı Rehberlikci olarak ata
                    </b-form-checkbox>
                </b-form-group>
                <!-- Form Actions -->
                <div class="d-flex mt-2">
                    <b-button variant="primary" class="mr-2" type="submit" @click="updateBranchUser">
                        Güncelle
                    </b-button>
                    <b-button variant="outline-secondary" @click="hide">
                        Vazgeç
                    </b-button>
                </div>
            </b-form>
        </template>

    </b-sidebar>

    <b-sidebar sidebar-class="sidebar-lg" width="30%" bg-variant="white" shadow backdrop no-header right v-model="openDetailsPanel">
        <template #default="{ hide }">
            <!-- Header -->
            <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
                <h5 class="mb-0">Yeni Kullanıcı Ekle</h5>
                <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
            </div>

            <!-- Body -->
            <div class="card-transaction px-2 py-1">
                <div class="transaction-item" @click="addLessonPanel = true">
                    <b-media no-body>
                        <b-media-aside>
                            <b-avatar rounded size="42" variant="light-success">
                                <feather-icon size="18" icon="PlusIcon" />
                            </b-avatar>
                        </b-media-aside>
                        <b-media-body>
                            <h6 class="transaction-title">Yeni Ders Ekle</h6>
                            <small>Öğretmenin Sınıft-Ders Ataması </small>
                        </b-media-body>
                    </b-media>
                    <div class="font-weight-bolder text-danger"></div>
                </div>
                <div class="transaction-item" v-for="modul in usersLesson" :key="modul.id">
                    <b-media no-body>
                        <b-media-aside>
                            <b-avatar rounded size="42" variant="light-primary">
                                <feather-icon size="18" icon="PocketIcon" />
                            </b-avatar>
                        </b-media-aside>
                        <b-media-body>
                            <h6 class="transaction-title">
                                {{ modul.lesson.name }}
                            </h6>
                            <small> {{ modul.class.name }}</small>
                            <h5>
                                {{
                                    modul.childClass.length>0?
                                    modul.childClass.map(e=>{
                                        return e.class.name
                                    }):'Alt Sınıf Seçilmedi'
                                }}
                            </h5>
                        </b-media-body>
                    </b-media>
                    <div class="font-weight-bolder text-success">
                        <b-button variant="flat-danger" class="btn-icon" @click="deleteTeacherClass(modul.id)">
                            <feather-icon icon="DeleteIcon" />
                        </b-button>
                        <b-button variant="flat-warning" class="btn-icon" @click="editTeacherClass(modul)">
                            <feather-icon icon="EditIcon" />
                        </b-button>
                    </div>
                </div>
            </div>

        </template>
    </b-sidebar>

    <!--Add Teacher Lesson Class--->
    <b-modal title="Yeni Sınıf/Ders Ekle" ok-title="Ekle" ok-only cancel-variant="outline-secondary" no-close-on-backdrop v-model="addLessonPanel" @ok="addUserLessonEvent">
        <b-form>
            <b-form-group label="Eklemek İstediğiniz Sınıf" label-for="vue-select">
                <v-select id="vue-select" v-model="addUserLessonRequest.classId" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :reduce="(clas) => clas.id" label="name" :options="classes" />
            </b-form-group>
            <b-form-group label="Eklemek İstediğiniz Ders" label-for="vue-select">
                <v-select id="vue-select" v-model="addUserLessonRequest.lessonId" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :reduce="(lesson) => lesson.id" label="name" :options="lessons" />
            </b-form-group>
            <b-form-group label="Eklemek İstediğiniz Alt Sınıf" label-for="vue-select">
                <v-select id="vue-select" multiple v-model="addUserLessonRequest.childClass" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :reduce="(clas) => clas.id" label="name" :options="childClass" />
            </b-form-group>
        </b-form>
        <b-overlay :show="show" no-wrap>
        </b-overlay>
    </b-modal>

</b-card>
</template>

<script>
import {
    BCard,
    BTable,
    BAvatar,
    BImg,
    BBadge,
    BSidebar,
    BTooltip,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    BMediaBody,
    BMedia,
    BMediaAside,
    BFormCheckbox,
    BInputGroupPrepend,
    BInputGroup,
    BOverlay,
    BButtonGroup
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Cleave from 'vue-cleave-component'
import 'cleave.js/dist/addons/cleave-phone.tr'
export default {
    components: {
        BButtonGroup,
        BOverlay,
        Cleave,
        BFormCheckbox,
        vSelect,
        ToastificationContent,
        BCard,
        BTable,
        BAvatar,
        BImg,
        BBadge,
        BSidebar,
        BForm,
        BFormGroup,
        BFormInput,
        BFormTextarea,
        BButton,
        BMediaBody,
        BMedia,
        BMediaAside,
        BInputGroupPrepend,
        BInputGroup,
        BTooltip
    },
    data() {
        return {
            show: false,
            gender: [{
                name: "ERKEK"
            }, {
                name: "KADIN"
            }, {
                name: "BELİRTİLMEMİŞ"
            }],
            fields: [
                { key: 'id', label: 'Ref' },
                { key: 'createdAt', label: 'Kayıt Tarihi' },
                { key: 'isActive', label: 'Durum' },
                { key: 'firstName', label: 'Adı' },
                { key: 'lastName', label: 'Soyadı' },
                { key: 'email', label: 'E-Posta Adresi' },
                { key: 'gsmNumber', label: 'Telefon Numarası' },
                { key: 'gender', label: 'Cinsiyet' },
                { key: 'isGuidance', label: 'Rehber Öğretmen' },
                { key: 'actions', label: 'İşlemler' },
            ],
            users: [],
            usersLesson: [],
            selectedUser: {},
            classes: [],
            childClass: [],
            lessons: [],
            addLessonPanel: false,
            addUserPanel: false,
            addUserLessonRequest: {
                refId: null,
                lessonId: 0,
                classId: 0,
                userId: 0,
                childClass: []
            },
            addUserRequest: {
                branchId: 0,
                password: "",
                email: "",
                gsmNumber: "",
                firstName: "",
                lastName: "",
                gender: "",
                idNumber: "",
                campaignEmail: true,
                campaignSms: true,
                campaignPhone: true,
                addZoom: false,
                isGuidance: false
            },
            openDetailsPanel: false,
            editUserPanel: false,
            editTeacherRequest: {

            }
        }
    },
    watch: {
        "addUserLessonRequest.classId": async function (newValue) {
            var childClass = await this.$http.get("Branch/Classes/" + newValue);
            this.childClass = childClass.data.data;
        }
    },
    mounted() {
        this.$root.$on('AddButtonClick', () => {
            this.addUserPanel = true;
        });
    },
    created() {
        this.getData();
        this.getClasses();
        this.getLessons();
    },
    methods: {
        editTeacherClass(userClass) {
            this.addUserLessonRequest = {
                refId: userClass.id,
                lessonId: userClass.lesson.id,
                classId: userClass.class.id,
                userId: userClass.user.id,
                childClass: []
            };
            userClass.childClass.forEach(element => {
                this.addUserLessonRequest.childClass.push(element.class.id);
            });
            this.addLessonPanel = true;
        },
        editTeacher(data) {
            this.editTeacherRequest = data;
            this.editUserPanel = true;
        },
        async getData() {
            var users = await this.$http.get("Branch/Teachers");
            this.users = users.data.data;
        },
        async getClasses() {
            var classes = await this.$http.get("Branch/Classes");
            this.classes = classes.data.data;
        },
        async getLessons() {
            var lessons = await this.$http.get("Branch/Lessons");
            this.lessons = lessons.data.data;
        },
        myTrim(x) {
            var replaced = x.replace(/\s+/g, '');
            return replaced;
        },
        async GetUserLesson() {
            this.show = false;
            this.addLessonPanel = false;
            var userLesson = await this.$http.get("Branch/TeacherClass/" + this.selectedUser.id);
            this.usersLesson = userLesson.data.data;
        },
        async openLessonDetails(user) {
            this.selectedUser = user;
            this.GetUserLesson();
            this.openDetailsPanel = true;
        },
        async deleteTeacherClass(refId) {
            this.$confirm({
                title: 'Sil',
                message: `Silmek İstediğinizden Emin misiniz?`,
                button: {
                    no: 'Vazgeç',
                    yes: 'Sil'
                },
                callback: async (confirm) => {
                    if (confirm) {
                        this.$http.delete("Branch/TeacherClass/" + refId).then(() => {
                            this.GetUserLesson();
                        });
                    }
                }
            });
        },
        async deleteTeacher(item) {
            this.$confirm({
                title: 'Sil',
                message: `Silmek İstediğinizden Emin misiniz?`,
                button: {
                    no: 'Vazgeç',
                    yes: 'Sil'
                },
                callback: async (confirm) => {
                    if (confirm) {
                        this.$http.delete("Branch/TeacherDelete/" + item.id).then(() => {
                            this.getData();
                        });
                    }
                }
            });
        },
        async addUserLessonEvent(e) {
            this.show = true;
            e.preventDefault();
            if (this.addUserLessonRequest.refId == null) {
                this.addUserLessonRequest.userId = this.selectedUser.id;
                this.$http.post("Branch/AddTeacherClass", this.addUserLessonRequest).then(() => {
                    this.GetUserLesson();
                    this.show = false;
                });
            } else {
                this.$http.put("Branch/UpdateTeacherClass", this.addUserLessonRequest).then(() => {
                    this.addUserLessonRequest.refId = null;
                    this.GetUserLesson();
                    this.show = false;
                });
            }
        },
        addBranchUser() {
            var request = this.addUserRequest;
            this.$http.post("Branch/AddTeacher", {
                    password: request.password,
                    email: request.email,
                    gsmNumber: this.myTrim(request.gsmNumber),
                    firstName: request.firstName,
                    lastName: request.lastName,
                    gender: request.gender,
                    idNumber: request.idNumber,
                    campaignEmail: true,
                    campaignSms: true,
                    campaignPhone: true,
                    addZoom: request.addZoom,
                    isGuidance: request.isGuidance
                }).then((data) => {
                    this.getData();
                    this.addUserPanel = false;
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'İşlem',
                            icon: 'BellIcon',
                            text: "İşlem Başarılı Kullanıcı Oluşturuldu",
                            variant: 'success'
                        },
                    });
                })
                .catch((error) => {
                    //console.log(error.response.data)
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Hata',
                            icon: 'BellIcon',
                            text: error.response.data.error.message,
                            variant: 'danger'
                        },
                    });
                });
        },
        updateBranchUser() {
            var request = this.editTeacherRequest;
            request.gsmNumber = this.myTrim(request.gsmNumber);
            this.$http.put("Branch/UpdateTeacher", request).then((data) => {
                    this.getData();
                    this.editUserPanel = false;
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'İşlem',
                            icon: 'BellIcon',
                            text: "İşlem Başarılı Kullanıcı Güncellendi",
                            variant: 'success'
                        },
                    });
                })
                .catch((error) => {
                    //console.log(error.response.data)
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Hata',
                            icon: 'BellIcon',
                            text: error.response.data.error.message,
                            variant: 'danger'
                        },
                    });
                });
        }
    }
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style><style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/components/variables-dark";

.card-company-table ::v-deep td .b-avatar.badge-light-company {
    .dark-layout & {
        background: $theme-dark-body-bg !important;
    }
}
</style>
